<template>
  <div>
    <component :is="selectedComponent" @copy-document="copyDocument" :data="this.selectedField" :isDataTableField="isDataTableField"
      v-on:settings="() => openSettings()"></component>
    <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
      <i class="el-icon-s-tools" />
 
    </span>
   </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateView from "../formComponentsView/DateView";
import SingleLineTextView from "../formComponentsView/SingleLineTextView";
import MultiLineTextView from "../formComponentsView/MultiLineTextView";
import NumberView from "../formComponentsView/NumberView";
import SelectView from "../formComponentsView/SelectView";
import FormulaView from "../formComponentsView/FormulaView";
import CurrencyView from "../formComponentsView/CurrencyView.vue";
import DateTimeView from "../formComponentsView/DateTimeView";
import PhoneCountryCodeView from "../formComponentsView/PhoneCountryCodeView.vue";


export default {
  name: "PayVariableView",
  components: {
    //view
    DateView,
    MultiLineTextView,
    SingleLineTextView,
    NumberView,
    SelectView,
    FormulaView,
    CurrencyView,
    DateTimeView,
    PhoneCountryCodeView
  },
  props: ["data", "value", "isActive", "isDataTableField"],
  computed: {
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("companyEntities", ["getSingleCompanyEntity"]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage"
    ]),

    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px; width: ${this.data.width
        }px !important;`;
    },
    // Tharuni Start
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size = this.data.styles.font_size
          ? this.data.styles.font_size
          : "";
        let bold =
          this.data.styles.font.style == 1 || this.data.styles.font.style == 3
            ? "bold"
            : "";
        let italic =
          this.data.styles.font.style == 2 || this.data.styles.font.style == 3
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    // Tharuni End
    getAllUsers() {
      return this.getUsersList && this.getUsersList.data
        ? this.getUsersList.data
        : [];
    },
    getAllEntities() {
      return this.getAllEntitiesData && this.getAllEntitiesData.data
        ? this.getAllEntitiesData.data
        : [];
    },
    getPrimaryDataForUser() {
      return user => {
        let primaryFields = user.user_type.allowed_fields.filter(
          e => e.primary
        );

        let data = [];

        primaryFields.forEach(field => {
          data.push(user[field.key]);
        });

        return data.join(" ");
      };
    },
    getPrimaryDataForEntity() {
      return entity => {
        let primaryFields = entity.entity_id.primary_fields || [];

        let data = [];
        primaryFields.forEach(field => {
          if (entity.entity_data) {
            data.push(entity.entity_data[field.key]);
          }
        });

        return data.join(" ");
      };
    }
  },
  data() {
    return {
      validations: [],
      options: [],
      selectedEntity: {},
      selectedField: {},
      selectedEntityFields: [],
      selectedComponent: "",
      actionFieldData: [],
      entitiesData: []
    };
  },
  mounted() {
    this.options = this.data.options || [];
    this.setFiledType();
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    setFiledType() {
      this.selectedField = { ...this.data };
      if (this.data.global_variable_pay_field && (this.data.global_variable_pay_field == "txn_id" || this.data.global_variable_pay_field == "gateway" || this.data.global_variable_pay_field == "name" || this.data.global_variable_pay_field == "email" || this.data.global_variable_pay_field == "paymentType" || this.data.global_variable_pay_field == "method" || this.data.global_variable_pay_field == "status" || this.data.global_variable_pay_field == "paymentSession")) {
        this.data.inputType = "SINGLE_LINE_TEXT";
        this.selectedField.inputType = "SINGLE_LINE_TEXT";
      }
      else if (this.data.global_variable_pay_field && this.data.global_variable_pay_field == "description") {
        this.selectedField.inputType = "MULTI_LINE_TEXT";
        this.data.inputType = "MULTI_LINE_TEXT";
        this.data.validations.rows = 3;
      }
      else if (this.data.global_variable_pay_field && this.data.global_variable_pay_field == "phoneNumber") {
        this.selectedField.inputType = "PHONE_COUNTRY_CODE";
        this.data.inputType = "PHONE_COUNTRY_CODE";
      }
      else if (this.data.global_variable_pay_field && (this.data.global_variable_pay_field == "paidAmount" || this.data.global_variable_pay_field == "totalPaidAmount" || this.data.global_variable_pay_field == "amount" || this.data.global_variable_pay_field == "amountRefunded")) {
        this.selectedField.inputType = "CURRENCY";
        this.data.inputType = "CURRENCY";
        this.data.validations.currency = "USD"; //Default for all fields further we will assign exact currency from pay variable 
      }
      else if (this.data.global_variable_pay_field && (this.data.global_variable_pay_field == "paymentDate" || this.data.global_variable_pay_field == "refundDate")) {
        this.selectedField.inputType = "DATE_TIME";
        this.data.inputType = "DATE_TIME";
      }
      else {
        this.data.inputType = "SINGLE_LINE_TEXT";
        this.selectedField.inputType = "SINGLE_LINE_TEXT";
      }
      this.setEntityFields();
    },

    // thaurni start
    openSettings() {
      this.$emit("settings");
    },
    // tharuni end
    async fetchOptions() {
      try {
        if (this.data.global_variable_entity_field.includes("~")) {
          let relationshipId = this.data.global_variable_entity_field.split(
            "~"
          )[0];
          this.selectedField = {
            action_button_repeating_template_relationships: [],
            input_type: "ENTITY",
            inputType: "ENTITY",
            content: "",
            selected_fields: [],
            formula: "",
            function_type: "",
            validations: {
              multiple: false,
              maxFiles: 1,
              minFiles: 1,
              max_document_files: 1,
              min_document_files: 1,
              required: false,
              type: "OBJECT_ID",
              data_type: "OBJECT_ID"
            },
            defaultCountry: "US",
            countryCodeSelector: true,
            countryDisable: false,
            styles: {
              font: {
                name: "Helvetica",
                size: 16,
                style: 0,
                color: "#000000"
              },
              labelStyle: this.data.styles ? this.data.styles.labelStyle : ""
            },
            properties: {
              hideLabel: false,
              prefix: false,
              _id: "634d6ed8e51da443f3a2b7f9",
              is_unique: false,
              personal_data: false,
              personal_data_type: "",
              is_encrypted: false
            },
            decimals: null,
            date_result_type: "",

            filled_by: "SENDER",
            type: "FIELD",

            is_global_variable: false,

            filters: [
              {
                field: "self#related_to/1",
                operator: "=",
                value: "",
                query_type: "AND",
                data_type: "RELATION",
                data_source: "self_field",
                value_field: this.data.parent_key,
                relation_entity_id: this.data.relationship_key
              }
            ],
            entity_id: relationshipId,
            visibility: true
          };
          this.setEntityFields();
        }
        else if (this.data.global_variable_entity_field) {
          let templateId = this.data.global_variable_entity_field.includes("#")
            ? this.data.global_variable_entity_field.split("#")[0]
            : this.data.global_variable_entity_field;
          let key = this.data.global_variable_entity_field.split("#")[1];

          if (templateId) {
            await this.$store.dispatch(
              "companyTemplates/fetchSingleCompanyTemplate",
              templateId
            );

            if (this.getSingleCompanyTemplate) {
              this.selectedEntityFields = this.getTemplateFields(
                this.getSingleCompanyTemplate
              );
              this.selectedField = this.selectedEntityFields.find(
                f => f.key == key
              );
              this.setEntityFields();
            }
          }
        }

        this.selectedField.height = this.data.height;
        this.selectedField.width = this.data.width;
      } catch (error) {
        console.log(error);
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach(e => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map(e => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    setEntityFields() {
      let fieldMap = {
        DATE: "DateView",
        FILE: "FileView",
        MULTI_LINE_TEXT: "MultiLineTextView",
        SINGLE_LINE_TEXT: "SingleLineTextView",
        MULTI_SELECT: "MultiSelectView",
        NUMBER: "NumberView",
        SELECT: "SelectView",
        LIST: "ListView",
        YES_OR_NO: "YesOrNoView",
        HEADING: "HeadingView",
        CHECKBOX: "CheckboxView",
        ESIGNATURE: "ESignatureView",
        GLOBAL_VAIRLABE: "GlobalVariableView",
        PARAGRAPH: "ParagraphView",
        SINGLE_LINE_CONTENT: "SingleLineContentView",
        AUTHORIZED_SIGNATURE: "AuthorizedSignatureView",
        IMAGE: "ImageView",
        FORMULA: "FormulaView",
        ACTION_BUTTON: "ActionButtonView",
        HTML: "HtmlView",
        AGGREGATAION: "AggregateFunctionView",
        ENTITY: "EntityView",
        CURRENCY: "CurrencyView",
        PHONE_COUNTRY_CODE: "PhoneCountryCodeView",
        DATE_TIME: "DateTimeView",
        TIME: "TimeView",
        RADIO: "RadioView",
        DATE_TIME_RANGE: "DateTimeRangeView",
        DATE_RANGE: "DateRangeView",
        WEEKDAYS: "WeekdaysView",
        TIME_RANGE: "TimeRangeView",
        CHECKBOX_GROUP: "CheckBoxGroupView",
        AGGREGATE_FUNCTION: "AggregateFunctionView",
        STAR_RATING: "StarRatingView",
      };
      this.selectedComponent = fieldMap[this.selectedField.inputType];
    },
    async getCompoentForField(data) {
      try {
        let params = "form=true&content=true";
        await this.$store.dispatch("standardFields/getFieldLists", params);
        if (this.getFList) {
          let dFieldList = [
            ...this.getFList.allFormFields,
            ...this.getFList.allContentFields
          ];
          let field = dFieldList.find(x => x.inputType == data.input_type);
          if (field) {
            return field.components.view;
          }
          return "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getFieldData(templateId) {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );
        if (this.getSingleCompanyTemplate) {
          this.actionFieldData.push(this.getSingleCompanyTemplate);
          return this.getSingleCompanyTemplate;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    "data.global_variable_entity_field": {
      async handler() {
        // do stuff
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
